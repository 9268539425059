import illustration from "../../public/static/images/svg/not_found.svg";
import Images from "./UserInterface/Images";
import LayoutCenter from "./UserInterface/LayoutCenter";
import Link from "next/link";

export const PageNotFoundBody = () => {
  return (
    <LayoutCenter margin="68px 0">
      <Images
        margin="54px 20px 32px 20px"
        mobileWidth="360px"
        width="420px"
        url={illustration}
      ></Images>
      <div className="notfound-text">
        <h1 className="notfound-title">Oops! Halaman tidak ditemukan</h1>
        <p>
          Tapi kamu bisa menikmati{" "}
          <Link
            href="/asuransi-cicilan-kredit"
            as="/asuransi-cicilan-kredit"
            legacyBehavior>
            <a className="notfound-red-text">
              <strong>Perlindungan Atas Pembayaran Cicilan</strong>
            </a>
          </Link>{" "}
          kamu dan kenyamanan{" "}
          <Link href="/belanja-di-toko" as="/belanja-di-toko" legacyBehavior>
            <a className="notfound-red-text">
              <strong>Berbelanja Di Toko</strong>
            </a>
          </Link>
          .
        </p>
        <style jsx>{`
          .notfound-red-text {
            color: var(--color-red);
          }

          @media only screen and (max-width: 600px) {
            .notfound-title {
              font-size: 24px;
            }
            .notfound-text {
              text-align: center;
              margin: 0 0 24px 0;
            }
          }
        `}</style>
      </div>
    </LayoutCenter>
  );
};
