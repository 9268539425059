type ImagesProps = {
  width?: string;
  url?: string;
  mobileWidth?: string;
  margin?: string;
  height?: string;
  heightMobile?: string;
  order?: string;
  marginMobile?: string;
};

const Images = ({
  width,
  url,
  mobileWidth,
  margin,
  height,
  heightMobile,
  order,
  marginMobile
}: ImagesProps) => {
  return (
    <div className="image-container">
      <img data-cy="img-cy" src={url}></img>
      <style jsx>{`
        .image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: ${margin ? margin : "16px"};
          max-width: ${width ? width : "auto"};
          width: 100%;
          height: ${height};
        }
        .image-container img {
          width: 100%;
        }

        @media only screen and (max-width: 600px) {
          .image-container {
            max-width: ${mobileWidth ? mobileWidth : "auto"};
            width: 100%;
            margin: ${marginMobile};
          }
          .image-container img {
            width: 100%;
          }
        }
        @media only screen and (max-width: 768px) {
          .image-container {
            order: ${order};
            height: ${heightMobile};
          }
        }
      `}</style>
    </div>
  );
};
export default Images;
