//toDo: check component props and prop types (alignItem)

type LayoutCenterProps = {
  column?: boolean;
  children: React.ReactNode;
  mobileDirection?: boolean;
  margin?: string;
  widthLayout?: string;
  alignItem?: boolean;
  marginMobile?: string;
  width?: string;
  alignSelf?: string;
  position?: string;
  top?: string;
  display?: boolean;
  alignMobile?: string;
  justifyMobile?: string;
  backgroundColor?: string;
  index?: string;
  borderRadius?: string;
  id?: string;
  style?: object;
  justifyDesktop?: string;
  boxShadow?: boolean;
  bottom?: string;
  padding?: string;
};

const LayoutCenter = ({
  column,
  children,
  mobileDirection,
  margin,
  widthLayout,
  alignItem,
  marginMobile,
  width,
  alignSelf,
  position,
  top,
  display,
  alignMobile,
  justifyMobile,
  backgroundColor,
  index,
  borderRadius,
  id,
  style,
  justifyDesktop,
  boxShadow = false,
  bottom,
  padding
}: LayoutCenterProps) => {
  return (
    <div id={id} style={style}>
      {children}
      <style jsx>{`
        div {
          background-color: ${backgroundColor};
          display: ${display ? "" : "flex"};
          position: ${position ? position : "auto"};
          top: ${top ? top : "auto"};
          flex-direction: ${column ? "column" : "row"};
          justify-content: ${justifyDesktop ? justifyDesktop : "center"};
          align-items: ${alignItem ? "" : "center"};
          max-width: ${widthLayout ? widthLayout : "940px"};
          align-self: ${alignSelf ? alignSelf : "center"};
          margin: ${margin ? margin : "24px 16px"};
          width: ${width ? width : "95%"};
          z-index: ${index};
          border-radius: ${borderRadius};
          bottom: ${bottom};
          box-shadow: ${boxShadow ? "0 -2px 4px 0 rgba(64,64,64,0.2)" : ""};
          padding: ${padding};
        }
        @media only screen and (max-width: 600px) {
          div {
            flex-direction: ${mobileDirection ? "column-reverse" : "column"};
            margin: ${marginMobile ? marginMobile : "0px"};
            align-items: ${alignMobile};
            justify-content: ${justifyMobile};
          }
        }
      `}</style>
    </div>
  );
};

export default LayoutCenter;
